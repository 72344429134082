import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/duc/Documents/downloads/thienlongson/src/components/blog-post-layout.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="h2" components={components}>{`Third`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    